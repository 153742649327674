import { CertificateType } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'

import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

interface IComponentProps {
    onSuccess: (skid: string) => void
    onSign: (skid: string) => void
    onCancel: () => void
}

export const ConfirmRemoveFromUsersModal: FC<IComponentProps> = ({ onSuccess, onCancel, onSign }) => {
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const onSelectCertificateHandler = (certificate: CertificateType) => {
        onSuccess(certificate.skid)
    }

    const onSignSelectCertificateHandler = (certificate: CertificateType) => {
        onSign(certificate.skid)
    }

    return (
        <MiniModal visible onCancel={onCancel} width="423px">
            <div>
                <Box direction="row" align="flex-start">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">
                        Подтверждение исключения из Реестра пользователей
                    </div>
                </Box>
                <Box direction="column" align="center" justify="right" margin={[0, 0, 0, 32]}>
                    <div className="modal-content-shift">&nbsp;</div>
                    <p className="modal-content-text">
                        Вы действительно подтверждаете, что хотите быть исключены из Реестра
                        пользователей?
                    </p>

                    <p className="modal-content-text">
                        Это действие необратимо, доступ к вашей учётной записи будет полностью
                        заблокирован, текущая сессия прервётся.
                    </p>
                </Box>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[0, 8, 8, 8]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button
                        borderRadius={12}
                        type="danger"
                        onClick={() => setIsVisibleCertificateModal(true)}
                    >
                        Да, исключить меня из Реестра
                    </Button>
                </Box>
            </div>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSelectCertificateHandler}
                onSign={onSignSelectCertificateHandler}
            />
        </MiniModal>
    )
}
