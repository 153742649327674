import { FC } from 'react'

import { Box, Radio, Space } from '@dltru/dfa-ui'

type RadioOption = {
    name: string
    label: string
    disabled?: boolean
}

interface IRadioPick {
    elements: RadioOption[]
    label: string
    onRadioPickChange?: (value: RadioOption) => void
    value?: any
}

export const RadioPick: FC<IRadioPick> = ({ elements = [], label, onRadioPickChange, value }) => {
    return (
        <Box direction="row" width="456px" margin={[0, 16, 0, 0]}>
            <div style={{ width: '160px', textAlign: 'right', marginRight: '16px' }}>
                <span>{label}</span>
            </div>
            <Radio.Group
                onChange={(e) => {
                    if (onRadioPickChange) {
                        onRadioPickChange(e.target.value)
                    }
                }}
                value={value}
            >
                <Space direction="vertical">
                    {elements.map((element) => (
                        <Radio key={element.label} value={element} disabled={element.disabled}>
                            {element.label}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Box>
    )
}
