import { finalDfaStatuses } from '@dltru/dfa-models'
import { Alert, Box, Button, Card, CardHeader, CheckUl, PageHeader, Space } from '@dltru/dfa-ui'
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { moneySelector } from '@store/money/selectors'
import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import { MoneyDetails } from '@pages/RemoveFromUsers/components/MoneyDetails'

import { ConfirmRemoveFromUsersModal } from './components/ConfirmRemoveFromUsersModal'

export const RemoveFromUsers: FC = () => {
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const [isModalShow, setIsModalShow] = useState(false)
    const uuid = useSelector(authSelector.selectUserUid)
    const isEmitter = useSelector(profileSelector.isEmitter)
    const isCheckBlocked = useSelector(profileSelector.selectIsUserBlocked)
    const moneyAccount = useSelector(moneySelector.selectAccount)
    const [isCheckWallet, setIsCheckWallet] = useState(false)
    const [isCheckEmitter, setIsCheckEmitter] = useState(!isEmitter)
    const isMount = useRef<boolean>()

    const onModalCancelHandler = () => {
        setIsModalShow(false)
    }

    const onModalSuccessHandler = (skid: string) => {
        reduxDispatch(profileDetailsSlice.actions.terminationUserPrefly(skid))
    }

    const onModalSuccessSignHandler = (skid: string) => {
        const callback = () => {
            navigate('/login')
        }
        reduxDispatch(profileDetailsSlice.actions.terminationUser({ callback, skid }))
    }

    useEffect(() => {
        if (uuid) {
            const checkWallet = async () => {
                const wallet = await api.lib.getWalletDfaBalances(uuid)
                if (isMount.current) {
                    const walletData = wallet?.data?.items?.[0]
                    setIsCheckWallet(
                        wallet?.data?.items === null ||
                            (walletData !== undefined &&
                                walletData.active_amount_dfa === 0 &&
                                walletData.blocked_amount_dfa === 0 &&
                                walletData.injunction_amount_dfa === 0),
                    )
                }
            }
            const checkEmitter = async () => {
                const dfas = await api.lib.getDfaList({ emitter_id: uuid, limit: 9000 })
                if (isMount.current) {
                    const notRedeemedDfa = dfas?.data?.items?.some(
                        ({ status }) => !finalDfaStatuses.includes(status),
                    )
                    setIsCheckEmitter(dfas.data === null || notRedeemedDfa === false)
                }
            }

            checkWallet()
            if (isEmitter) {
                checkEmitter()
            }
        }
    }, [uuid, isEmitter])
    useEffect(() => {
        isMount.current = true
        return () => {
            isMount.current = false
        }
    }, [])

    const checkItems = [
        { title: 'на вашем Кошельке не должно быть непогашенных ЦФА,', isCheck: isCheckWallet },
        {
            title: 'у вас не должно быть неисполненных обязательств по выпущенным ЦФА,',
            isCheck: isCheckEmitter,
        },
        { title: 'в отношении вас не должно быть введено Блокирование,', isCheck: !isCheckBlocked },
        {
            title: 'на вашем лицевом счете не должно быть денежных средств',
            isCheck: moneyAccount?.balance.available === 0,
        },
    ]

    const disableBtn = checkItems.some(({ isCheck }) => !isCheck)
    const terminateUser = () => {
        setIsModalShow(true)
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Заявление на исключение из реестра пользователей"
                    onBack={() => navigate(-1)}
                />
            </Box>
            <Card>
                <CardHeader title="Условия исключения из реестра пользователей" padding={[16, 0]} />
                <Box padding={[16, 16, 40, 16]}>
                    Для исключения из реестра пользователей необходимо выполнить следующие условия:
                    <Box margin={[16, 0, 24, 0]}>
                        <CheckUl items={checkItems} />
                    </Box>
                    <MoneyDetails />
                    <Alert
                        type="warning"
                        showIcon
                        description="После исключения из реестра пользователей ваш доступ к Платформе будет прекращен. Данная операция не может быть отменена."
                    />
                </Box>
                <Space size={16}>
                    <Button
                        type="primary"
                        borderRadius={12}
                        disabled={disableBtn}
                        onClick={terminateUser}
                    >
                        Подать заявление
                    </Button>
                </Space>
            </Card>

            {isModalShow && (
                <ConfirmRemoveFromUsersModal
                    onSign={onModalSuccessSignHandler}
                    onSuccess={onModalSuccessHandler}
                    onCancel={onModalCancelHandler}
                />
            )}
        </>
    )
}
