import React, { useContext, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { afterSendingStatuses } from '@dltru/dfa-models'
import { Box, PageHeader } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { AppState, AppStore } from '@store/index'
import { roleFormSelector } from '@store/roleForms/selectors'

import { RoleFormContext, ViewState } from '@components/Layout/RoleFormsLayout'

import { AfterSendingView } from './components/RoleFormsViews/AfterSendingView'
import { BeforeSendingView } from './components/RoleFormsViews/BeforeSendingView'

export const RoleForms = () => {
    const { viewState, setViewState } = useContext(RoleFormContext)
    const mainStatus = useSelector(roleFormSelector.selectMainStatus)
    const userName = useSelector(authSelector.selectUserName)
    const isLoaded = useSelector((store: AppState) => store.auth.isprofileLoaded)

    useLayoutEffect(() => {
        if (!isLoaded) {
            return
        }

        if (mainStatus && afterSendingStatuses.includes(mainStatus)) {
            setViewState(ViewState.afterSending)
        } else if (!mainStatus) {
            setViewState(ViewState.beforeSending)
        }
    }, [mainStatus, isLoaded])

    return (
        <>
            <Box padding={16}>
                <PageHeader title={`Добро пожаловать, ${userName}`} />
            </Box>
            {viewState === ViewState.afterSending && isLoaded && <AfterSendingView />}
            {viewState === ViewState.beforeSending && isLoaded && <BeforeSendingView />}
        </>
    )
}
