import { Divider, MoneyAccountBlock, Requisites } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

export const MoneyDetails: FC = () => {
    const moneyAccount = useSelector(moneySelector.selectAccount)
    const moneyBlock = {
        label: 'Текущий остаток денежных средств',
        value: (
            <MoneyAccountBlock
                accountNumber={moneyAccount?.number}
                balance={moneyAccount?.balance.available}
                width={400}
            />
        ),
    }

    return (
        <>
            <Requisites requisites={[moneyBlock]} size="large" />
            <Divider />
        </>
    )
}
