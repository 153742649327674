import { FC, useState } from 'react'

import dayjs from 'dayjs'

import { TAgreementData } from '@dltru/dfa-models'
import {
    Box,
    Button,
    Checkbox,
    CheckboxChangeEvent,
    MiniModal,
    ModalProps,
    QuestionCircle,
} from '@dltru/dfa-ui'

import { PersonalDataAgreement } from '@components/Agreements/PersonalDataAgreement'
import { PrivacyAgreement } from '@components/Agreements/PrivacyAgreement'

interface IComponentProps {
    noClientRepresentative?: boolean
    noMainAnketa?: boolean
    withoutAgreements?: boolean
    onSubmit: (agreements?: Partial<TAgreementData>) => void
}

export const ApproveAnketaModal: FC<IComponentProps & ModalProps> = ({
    noClientRepresentative,
    noMainAnketa,
    onCancel,
    onSubmit,
    withoutAgreements,
    ...rest
}) => {
    const [clientChecked, setClientChecked] = useState(false)
    const [clientRepresentativeChecked, setClientRepresentativeChecked] = useState(false)
    const [personalDataAgreementChecked, setPersonalDataAgreementChecked] = useState(false)
    const [privacyAgreementChecked, setPrivacyAgreementChecked] = useState(false)

    const [personalDataAgreementModal, setPersonalDataAgreementModal] = useState(false)
    const [privacyAgreementModal, setPrivacyAgreementModal] = useState(false)

    const isDisableBtn =
        (!personalDataAgreementChecked && !withoutAgreements) ||
        (!noMainAnketa && !clientChecked) ||
        (!noClientRepresentative && !clientRepresentativeChecked) ||
        !privacyAgreementChecked

    const handleOk = () => {
        onSubmit(
            personalDataAgreementChecked ? { personal_data_agreement: dayjs().unix() } : undefined,
        )
    }

    return (
        <MiniModal width={423} onCancel={onCancel} {...rest}>
            <div>
                <Box direction="row" align="center">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">
                        <span>Отправить Анкеты на проверку</span>
                    </div>
                </Box>
                <div className="modal-content">
                    <p className="modal-content-text">
                        Вы действительно хотите отправить Анкеты на проверку Оператору ИС?
                    </p>
                    <Checkbox.Group>
                        {!noClientRepresentative && (
                            <Checkbox
                                value="accept-client-representative"
                                onChange={(event: CheckboxChangeEvent) =>
                                    setClientRepresentativeChecked(event.target.checked)
                                }
                            >
                                Подтверждаю полноту и достоверность данных Представителя
                                Пользователя
                            </Checkbox>
                        )}

                        {!noMainAnketa && (
                            <Checkbox
                                value="accept-client"
                                onChange={(event: CheckboxChangeEvent) =>
                                    setClientChecked(event.target.checked)
                                }
                            >
                                Подтверждаю полноту и достоверность данных Пользователя
                            </Checkbox>
                        )}
                        {!withoutAgreements && (
                            <Checkbox
                                value="personal-data-agreement"
                                onChange={(event: CheckboxChangeEvent) =>
                                    setPersonalDataAgreementChecked(event.target.checked)
                                }
                            >
                                Даю согласие на обработку{' '}
                                <Button
                                    type="linkText"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setPersonalDataAgreementModal(true)
                                    }}
                                >
                                    персональных данных
                                </Button>
                            </Checkbox>
                        )}

                        <Checkbox
                            value="confidentiality_agreement"
                            onChange={(event: CheckboxChangeEvent) =>
                                setPrivacyAgreementChecked(event.target.checked)
                            }
                        >
                            Я ознакомлен(а) с{' '}
                            <Button
                                type="linkText"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setPrivacyAgreementModal(true)
                                }}
                            >
                                Соглашением о конфиденциальности
                            </Button>
                        </Checkbox>
                    </Checkbox.Group>
                </div>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[0, 8, 8, 8]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button
                        borderRadius={12}
                        type="primary"
                        disabled={isDisableBtn}
                        onClick={handleOk}
                    >
                        Да, отправить
                    </Button>
                </Box>
            </div>
            <PersonalDataAgreement
                isModalVisible={personalDataAgreementModal}
                setIsModalVisible={setPersonalDataAgreementModal}
            />
            <PrivacyAgreement
                isModalVisible={privacyAgreementModal}
                setIsModalVisible={setPrivacyAgreementModal}
            />
        </MiniModal>
    )
}
